.tooltip span {
  display: block;
}
.tooltip:hover span {
  display: block;
  position: fixed;
  overflow: hidden;
}

.container {
  display: flex;
  margin-right: 20px;
  /* justify-content: center;
  margin: 0 auto; */
}

.container span {
  display: none;
}

.container:hover span {
  display: inline;
  cursor: pointer;
}
